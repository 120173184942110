<template>
  <section class="content-slider-container">
    <div class="immerse-row">
      <h3>Our Experts from the<br /><span>United States!</span></h3>
      <p>
        We are truly global in every way!
        <br />
        We are proud to have a select group of American English experts on our
        team. Their vast expertise and authentic linguistic culture guarantee
        you a rich and distinguished learning experience. Take advantage of
        their deep knowledge of American English to enhance your language
        skills.
      </p>
    </div>

    <div class="carousel-container">
      <button @click="prevSlide" class="carousel-nav left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </button>
      <Carousel
        ref="carousel"
        :itemsToShow="itemsToShow"
        :itemsToScroll="itemsToScroll"
        :wrapAround="true"
        :mouseDrag="true"
        :autoplay="4000"
        :transition="500"
        :gap="20"
        @slideEnd="handleSlideEnd"
        class="image-gallery"
      >
        <Slide v-for="(image, index) in images" :key="index">
          <div class="image-item card">
            <img
              loading="lazy"
              :src="image.src"
              :alt="image.name"
              class="image-thumbnail"
            />
            <div class="card-content">
              <div class="icon-text-container">
                <h4>
                  {{ image.name }}
                  <svg
                    fill="#fff"
                    height="55px"
                    width="55px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 85 85"
                    xml:space="preserve"
                    stroke="#fff"
                  >
                    <defs>
                      <linearGradient
                        id="grad1"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="100%"
                      >
                        <stop
                          offset="0%"
                          style="stop-color: #f47d21; stop-opacity: 1"
                        />
                        <stop
                          offset="100%"
                          style="stop-color: #ff9d52; stop-opacity: 1"
                        />
                      </linearGradient>
                    </defs>
                    <circle
                      cx="32"
                      cy="32"
                      r="30"
                      stroke="#fff"
                      stroke-width="2"
                      fill="url(#grad1)"
                    />
                    <g id="Play">
                      <path
                        d="M46.0136986,31.1054993L25.1973,20.6973c-0.3096008-0.1532993-0.6777992-0.1387005-0.9727001,0.0438995 C23.9297009,20.9237995,23.75,21.2451,23.75,21.5918007v20.8163986c0,0.3467026,0.1797009,0.6679993,0.4745998,0.8506012 C24.3848,43.3583984,24.5674,43.4081993,24.75,43.4081993c0.1532993,0,0.3057003-0.035099,0.4473-0.1054001l20.8163986-10.4081993 c0.3388023-0.1699982,0.5527-0.5157013,0.5527-0.8945999C46.5663986,31.6210995,46.3525009,31.2754002,46.0136986,31.1054993z M25.75,40.7901001v-17.580101L43.330101,32L25.75,40.7901001z"
                      ></path>
                      <path
                        d="M32,0C14.3268995,0,0,14.3268995,0,32s14.3268995,32,32,32s32-14.3269005,32-32S49.6730995,0,32,0z M32,62 C15.4579,62,2,48.542099,2,32C2,15.4580002,15.4579,2,32,2c16.5419998,0,30,13.4580002,30,30C62,48.542099,48.5419998,62,32,62z"
                      ></path>
                    </g>
                  </svg>
                </h4>
              </div>
              <p class="desc-div">{{ image.desc }}</p>
              <p class="navigate-link" @click="navigateTo(image.link)">
                Know More
                <svg
                  fill="#fff"
                  height="16px"
                  width="16px"
                  viewBox="0 0 330 330"
                  xml:space="preserve"
                >
                  <path
                    d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                  ></path>
                </svg>
              </p>
            </div>
            <div class="hover-svg-container">
              <svg
                class="hover-svg1"
                width="222"
                height="245"
                viewBox="0 0 222 245"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M170.617 245H0.704102V239.877H67.3402L77.7946 229.422H164.713L182.854 211.281H197.086L215.943 192.424V43.8531H221.066V194.543L170.617 245ZM184.977 216.404L166.836 234.546H79.9177L74.5866 239.877H168.49L191.959 216.404H184.974H184.977Z"
                  fill="#e1e1e1bf"
                />
                <path
                  d="M221.07 40.0078H215.946V31.0392H221.07V40.0078Z"
                  fill="#e1e1e1bf"
                />
                <path
                  d="M221.07 27.1943H215.946V0.288572H221.07V27.1943Z"
                  fill="#e1e1e1bf"
                />
              </svg>
              <svg
                class="hover-svg2"
                width="250"
                height="150"
                viewBox="0 0 250 150"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M51.3823 0H221.295V5.12337H154.659L144.204 15.5778H57.2862L39.1449 33.719H24.9133L6.05603 52.5763V201.147H0.932663V50.4568L51.3823 0ZM37.0218 28.5956L55.1631 10.4544H142.081L147.412 5.12337H53.509L30.0403 28.5956H37.0254H37.0218Z"
                  fill="#e1e1e1bf"
                />
                <path
                  d="M0.929364 204.992H6.05273V213.961H0.929364V204.992Z"
                  fill="#e1e1e1bf"
                />
                <path
                  d="M0.929364 217.806H6.05273V244.711H0.929364V217.806Z"
                  fill="#e1e1e1bf"
                />
              </svg>
            </div>
          </div>
        </Slide>
      </Carousel>
      <button @click="nextSlide" class="carousel-nav right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 19.5L15.75 12l-7.5-7.5"
          />
        </svg>
      </button>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      itemsToShow: 5,
      itemsToScroll: 1,
      images: [
        {
          id: 11,
          src: "https://monglish.b-cdn.net/teachers/11.webp",
          name: "Esraa Mohamed",
          desc: "Hello, I am Esraa Mohamed, an English teacher at Monglish Academy...",
          link: "/ar/teachers/eleven",
        },
        {
          id: 28,
          src: "https://monglish.b-cdn.net/teachers/28.webp",
          name: "Amani Mohamed",
          desc: "Hello, I am Amani Mohamed, a teacher at Monglish Academy...",
          link: "/ar/teachers/twenty-eight",
        },
        {
          id: 17,
          src: "https://monglish.b-cdn.net/teachers/17.webp",
          name: "Omar Gamal",
          desc: "Hello, my name is Omar Gamal, a teacher at Monglish Academy...",
          link: "/ar/teachers/seventeen",
        },
        {
          id: 13,
          src: "https://monglish.b-cdn.net/teachers/13.webp",
          name: "Noura Al Sharif",
          desc: "Hello, my name is Noura Al Sharif, a trainer in teaching English...",
          link: "/ar/teachers/thirteen",
        },
        {
          id: 14,
          src: "https://monglish.b-cdn.net/teachers/14.webp",
          name: "Jihad El Sayed",
          desc: "Hello, my name is Jihad El Sayed, an experienced English teacher...",
          link: "/ar/teachers/fourteen",
        },
        {
          id: 16,
          src: "https://monglish.b-cdn.net/teachers/16.webp",
          name: "Nahid Mostafa",
          desc: "Hello, I am Nahid Mostafa, a teacher with extensive experience in teaching English...",
          link: "/ar/teachers/sixteen",
        },
        {
          id: 15,
          src: "https://monglish.b-cdn.net/teachers/15.webp",
          name: "Mahmoud Gamal",
          desc: "Hello, my name is Mahmoud Gamal, a certified teacher with experience...",
          link: "/ar/teachers/fifteen",
        },
        {
          id: 26,
          src: "https://monglish.b-cdn.net/teachers/26.webp",
          name: "Esraa Eid",
          desc: "Hello, I am Esraa Eid, a certified English teacher...",
          link: "/ar/teachers/twenty-six",
        },
        {
          id: 20,
          src: "https://monglish.b-cdn.net/teachers/20.webp",
          name: "Sara Magdy",
          desc: "Hello, I am Sara Magdy, an English teacher at Monglish Academy...",
          link: "/ar/teachers/tweenty",
        },
        {
          id: 27,
          src: "https://monglish.b-cdn.net/teachers/27.webp",
          name: "Alaa Mahjoub",
          desc: "Hello, my name is Alaa Mahjoub, a teacher with long experience...",
          link: "/ar/teachers/twenty-seven",
        },
        {
          id: 12,
          src: "https://monglish.b-cdn.net/teachers/12.webp",
          name: "Maryam Adel",
          desc: "Hello, my name is Maryam Adel, an English trainer at Monglish Academy...",
          link: "/ar/teachers/twelve",
        },
        {
          id: 19,
          src: "https://monglish.b-cdn.net/teachers/19.webp",
          name: "Nada Yahya",
          desc: "Hello, my name is Nada Yahya, an experienced English teacher...",
          link: "/ar/teachers/ninteen",
        },
        {
          id: 21,
          src: "https://monglish.b-cdn.net/teachers/21.webp",
          name: "Amira Adel",
          desc: "Hello, I am Amira Adel, a certified teacher at Monglish International Academy...",
          link: "/ar/teachers/twenty-one",
        },
        {
          id: 3,
          src: "https://monglish.b-cdn.net/teachers/3.webp",
          name: "Heba Khalifa",
          desc: "Hello, my name is Heba Khalifa, an English teacher at Monglish International Academy. I work as an English language teacher...",
          link: "/en/teachers/three",
        },
        {
          id: 9,
          src: "https://monglish.b-cdn.net/teachers/9.webp",
          name: "Walaa Faiq",
          desc: "Hello, I am Walaa Faiq, a trainer at Monglish International Academy for teaching English. I have experience...",
          link: "/en/teachers/nine",
        },
        {
          id: 5,
          src: "https://monglish.b-cdn.net/teachers/5.webp",
          name: "Mai Al-Tawil",
          desc: "Hello, my name is Mai Al-Tawil, a certified trainer in teaching English as a foreign language with over 10 years of experience...",
          link: "/en/teachers/five",
        },
        {
          id: 4,
          src: "https://monglish.b-cdn.net/teachers/4.webp",
          name: "Kholoud",
          desc: "Hello, I am Kholoud, an English language trainer at Monglish International Academy. I graduated from the Faculty of Arts...",
          link: "/en/teachers/four",
        },
        {
          id: 2,
          src: "https://monglish.b-cdn.net/teachers/2.webp",
          name: "Fatima Hassan",
          desc: "Hello everyone, my name is Fatima Hassan, a certified trainer in teaching English as a foreign language at the Academy...",
          link: "/en/teachers/two",
        },
        {
          id: 18,
          src: "https://monglish.b-cdn.net/teachers/18.webp",
          name: "Hagar Fathi",
          desc: "Hello, my name is Hagar Fathi, an experienced English teacher...",
          link: "/ar/teachers/eighteen",
        },
        {
          id: 22,
          src: "https://monglish.b-cdn.net/teachers/22.webp",
          name: "Noura Hafiz",
          desc: "Hello, I am Noura Hafiz, a teacher with experience in teaching English...",
          link: "/ar/teachers/twenty-two",
        },
        {
          id: 29,
          src: "https://monglish.b-cdn.net/teachers/29.webp",
          name: "Shaimaa Samir",
          desc: "Hello, I am Shaimaa Samir, a teacher with long experience in teaching English...",
          link: "/ar/teachers/twenty-nine",
        },
        {
          id: 25,
          src: "https://monglish.b-cdn.net/teachers/25.webp",
          name: "Imane Mohamed",
          desc: "Hello, my name is Imane Mohamed, a certified teacher at Monglish Academy...",
          link: "/ar/teachers/twenty-five",
        },
        {
          id: 23,
          src: "https://monglish.b-cdn.net/teachers/23.webp",
          name: "Fatima Abdelkader",
          desc: "Hello, I am Fatima Abdelkader, a trainer in teaching English...",
          link: "/ar/teachers/twenty-three",
        },
        {
          id: 30,
          src: "https://monglish.b-cdn.net/teachers/30.webp",
          name: "Mona Al Alim",
          desc: "Hello, my name is Mona Al Alim, a teacher at Monglish Academy...",
          link: "/ar/teachers/thirty",
        },
        {
          id: 24,
          src: "https://monglish.b-cdn.net/teachers/24.webp",
          name: "Hadeer Abdelghafar",
          desc: "Hello, I am Hadeer Abdelghafar, a teacher at Monglish Academy...",
          link: "/ar/teachers/twenty-four",
        },
        {
          id: 1,
          src: "https://monglish.b-cdn.net/teachers/1.webp",
          name: "Naila",
          desc: "Hello, I am Naila, a certified English language trainer from the USA...",
          link: "/en/teachers/one",
        },
        {
          id: 6,
          src: "https://monglish.b-cdn.net/teachers/6.webp",
          name: "Caitlin",
          desc: "Hello, my name is Caitlin, I work here at Monglish International Academy, and I have over 10 years of experience...",
          link: "/en/teachers/six",
        },
        {
          id: 10,
          src: "https://monglish.b-cdn.net/teachers/10.webp",
          name: "Abdulrahman Eid",
          desc: "Hello, I am Abdulrahman Eid, an English teacher at Monglish Academy...",
          link: "/en/teachers/ten",
        },
        {
          id: 8,
          src: "https://monglish.b-cdn.net/teachers/8.webp",
          name: "Mona",
          desc: "Hello everyone, I am Professor Mona, an English teacher at Monglish International Academy. I have a degree in...",
          link: "/en/teachers/eight",
        },
      ],
    };
  },
  mounted() {
    this.updateItemsToShow();
    window.addEventListener("resize", this.updateItemsToShow);
    this.setupLazyLoading();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateItemsToShow);
    if (this.imageObserver) {
      this.imageObserver.disconnect(); // Clean up the observer
    }
  },
  computed: {
    visibleImages() {
      return this.images.slice(
        this.currentSlide,
        this.currentSlide + this.itemsToShow
      );
    },
  },
  methods: {
    prevSlide() {
      this.$refs.carousel.prev();
    },
    nextSlide() {
      this.$refs.carousel.next();
    },
    navigateTo(link) {
      this.$router.push(link);
    },
    setCurrentSlide(index) {
      this.currentSlide = index * this.imagesPerView;
    },
    updateItemsToShow() {
      const width = window.innerWidth;
      if (width >= 1440) {
        this.itemsToShow = 5;
      } else if (width >= 786) {
        this.itemsToShow = 3;
      } else {
        this.itemsToShow = 1;
      }
    },
    setupLazyLoading() {
      // Create an IntersectionObserver instance
      this.imageObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const img = entry.target;
              // Set the `src` attribute when the image is in view
              img.src = img.dataset.src;
              img.classList.add("fade-in"); // Optional: Add a fade-in effect
              observer.unobserve(img); // Stop observing once loaded
            }
          });
        },
        {
          root: null, // Use the viewport as the root
          threshold: 0.1, // Trigger when 10% of the image is visible
        }
      );

      // Observe all images with the `data-src` attribute
      const images = document.querySelectorAll(".image-thumbnail[data-src]");
      images.forEach((img) => this.imageObserver.observe(img));
    },
  },
};
</script>

<style scoped>
.content-slider-container {
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  direction: ltr;
}
.immerse-sec2 {
  padding: 1em 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.immerse-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2em;
  margin: 1em 0;
  font-family: "DIN Next LT Arabic", sans-serif;
}
.immerse-row h3 {
  color: #165e84;
  font-size: 2.8rem;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}
.immerse-row h3 span {
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}
.immerse-row p {
  font-size: 1.375rem;
  color: gray;
  font-weight: 500;
  max-width: 70%;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .immerse-row {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }
  .immerse-row h3 {
    width: 20%;
    margin: 0;
    padding-right: 50px;
  }
  .immerse-row p {
    width: 80%;
    margin: 0;
    padding-left: 25px;
  }
}
.carousel-nav:hover {
  color: #2178a6;
}
.carousel-nav:active {
  color: #fff;
}
@media (max-width: 769px) {
  .carousel-nav.left {
    left: 0;
  }
  .carousel-nav.right {
    right: 0;
  }
}
.carousel-container {
  position: relative;
}
.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  color: #165e84;
}
.carousel-nav.left {
  left: 10px;
}
.carousel-nav.right {
  right: 10px;
}
.icon-text-container {
  display: flex;
  align-items: center;
  direction: ltr;
}
.desc-div {
  margin-bottom: 20px;
}
.icon-text-container h4 {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
  text-align: left;
  font-size: 32px;
}
.icon-text-container svg {
  margin-left: 5px;
  margin-bottom: -15px;
}
.image-gallery {
  width: 95%;
  margin: 0 auto;
}
.image-item {
  width: 400px;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin: 0 10px;
  transition: transform 0.3s ease;
}
.image-thumbnail {
  width: 100%;
  height: 500px;
  border-radius: 25px;
  object-fit: cover;
  will-change: transform; /* Optional: hint to Safari to optimize rendering */
}
.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  direction: rtl;
}
.image-item:hover .card-content {
  opacity: 1;
  background: linear-gradient(180deg, rgba(45, 45, 45, 0) 0%, #171717 100%);
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
.card-content p {
  font-size: 16px;
  color: #fff;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navigate-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  margin-bottom: 15px;
  font-weight: bold;
  z-index: 100;
}
.navigate-link svg {
  margin-left: 8px;
  transition: transform 0.3s ease;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-item {
  position: relative;
  overflow: hidden;
}
.hover-svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.image-item:hover .hover-svg-container {
  opacity: 1;
}
.hover-svg2 {
  position: absolute;
  left: 3%;
  right: 50%;
  top: 2%;
  bottom: 50%;
}
.hover-svg1 {
  position: absolute;
  right: 3%;
  top: 48%;
}
@supports (-webkit-touch-callout: none) {
  /* Safari-specific styles */
  .image-thumbnail {
    transform: none; /* Test if removing transform fixes the issue */
  }
}
</style>