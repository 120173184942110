<template>
  <div class="teacher_page">
    <div class="teacher_container">
      <div class="teacher_image">
        <img loading="lazy" src="https://monglish.b-cdn.net/teachers/17.webp" alt="Teacher Walaa" />
      </div>
      <div class="teacher_info">
        <h4 class="teacher_greeting">أهلا, انا</h4>
        <h2 class="teacher_name">عمر جمال</h2>
        <p class="teacher_description">
          مدرس لغة إنجليزية ذو خبرة ست سنوات، أحرص على تقديم حصص ديناميكية تركز
          على الطلاب من خلال دمج نظريات اللغة مع أنشطة عملية ممتعة. أخلق بيئة
          داعمة تتيح للطلاب ممارسة التحدث والاستماع والقراءة والكتابة بثقة.
          باستخدام دروس تفاعلية وسيناريوهات حياتية وسرد القصص، أسعى لبناء أساس
          قوي في القواعد والمفردات والنطق، مع التركيز على تطوير مهارات التواصل
          عبر أنشطة مثل لعب الأدوار والألعاب والمشاريع الجماعية. تُصمم كل حصة
          وفق احتياجات الطلاب الفردية لضمان تقدمهم بثقة ونجاح في التواصل
          الأكاديمي واليومي.
        </p>
        <div class="teacher_stats">
          <div>
            <span class="stat_number">86</span>
            <span class="stat_label">طالب</span>
          </div>
          <div>
            <span class="stat_number">14,487</span>
            <span class="stat_label">حصة المنتهية</span>
          </div>
        </div>
        <div class="teacher_tags">
          <span>BA in English Literature</span>
        </div>
        <router-link to="/ar/package">
          <button class="subscribe_button">اشترك معنا الآن</button>
        </router-link>
      </div>
    </div>
  </div>

  <div class="kids-sec8">
    <div class="kids-sec8-row">
      <h4>الآراء الإيجابية <br /><span> لأولياء الأمور</span></h4>
      <p>
        نظرا للتفوق المبهر الذي يحققه خريجو أكاديمية مونجلش الدولية، خصوصا
        بالنسبة للأطفال والمراهقين، فإن أولياء أمورهم فخورين بما وصل إليه
        أبنائهم، وسعادتهم بالتطوير السريع الذي شهده أبنائهم والتي يعربون عنها في
        كل مناسبة تجعلنا نشعر بالفخر أكثر، وتزيدنا عزما على الاجتهاد من أجل
        التطوير والتحسين المستمر للدورات التي نقدمها، من أجل الوصول إلى أفضل
        النتائج لطلابنا دائما. إن مونجلش وصلت إلى ما وصلت إليه بدعم هؤلاء
        الرائعين!
      </p>
    </div>

    <div class="adult-swiper">
      <div ref="swiperContainer" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(review, index) in reviews" :key="index" class="swiper-slide">
            <p>
              <span class="review-stars">{{ "★".repeat(review.stars) }}</span>
            </p>
            <p>{{ review.review }}</p>
            <p class="review-by">{{ review.by }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="slider-3d">
    <h4>ما رأيكم في سماع آرائهم من القلب؟</h4>
    <div class="carousel-container">
      <button class="slider-nav left" @click="moveLeft">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>

      <transition-group name="slide" tag="div" class="carousel-track">
        <div v-for="(item, index) in generateItems()" :key="index" :class="['item', 'level' + item.level]">
          <img :src="item.src" :alt="'Image ' + index" @click="openPopup(item.videoSrc)" class="image-thumbnail" />
          <svg height="80px" width="80px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 491.444 491.444" xml:space="preserve" fill="#000000"
            class="video-icon">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path style="fill: #ff9d52"
                d="M0.11,245.721c-1.434,61.225,11.068,122.791,39.645,145.883c80.101,64.726,331.832,64.726,411.932,0c28.577-23.093,41.079-84.659,39.646-145.883H0.11z">
              </path>
              <path style="fill: #f47d21"
                d="M451.687,105.91c-72.82-72.82-339.111-72.82-411.932,0C15.231,130.434,1.463,187.925,0.11,245.721h491.223C489.98,187.925,476.213,130.434,451.687,105.91z">
              </path>
              <path style="fill: #ffffff"
                d="M320.941,232.105L190.51,136.284c-11.159-8.199-26.9-0.231-26.9,13.617v95.82h164.225C327.834,240.6,325.537,235.481,320.941,232.105z">
              </path>
              <path style="fill: #ffffff"
                d="M190.51,355.161l130.432-95.823c4.595-3.375,6.893-8.496,6.893-13.617H163.609v95.824C163.609,355.391,179.351,363.359,190.51,355.161z">
              </path>
            </g>
          </svg>
        </div>
      </transition-group>

      <button class="slider-nav right" @click="moveRight">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>

    <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="closePopup">&times;</button>
        <iframe v-if="currentVideoSrc" :src="currentVideoSrc" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen class="popup-video"></iframe>
      </div>
    </div>
  </div>

  <div class="promise-sec3-space">
    <div class="promise-sec3">
      <div class="promise-sec3-inner">
        <div class="text-content-promise">
          <h3>نحرص دائما علي تطوير مهارات معلمينا</h3>
          <h4>للوصول لافضل مستوي ممكن</h4>
          <p>لذالك يقام تدريب CPD كل أسبوع لكل المدرسين في BRITISHY</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "TeacherPage",
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: "",
      active: 0,
      items: [
        {
          src: "https://monglish.b-cdn.net/video/20.webp",
          videoSrc: "https://www.youtube.com/embed/u4pJkaKFDlo?autoplay=1",
        },
        {
          src: "https://monglish.b-cdn.net/video/21.webp",
          videoSrc: "https://www.youtube.com/embed/QkotruhuVPM?autoplay=1",
        },
        {
          src: "https://monglish.b-cdn.net/video/22.webp",
          videoSrc: "https://www.youtube.com/embed/q473RldRfrs?autoplay=1",
        },
        {
          src: "https://monglish.b-cdn.net/video/23.webp",
          videoSrc: "https://www.youtube.com/embed/fcdBZnoxqdg?autoplay=1",
        },
      ],
      autoSlideInterval: null,
      activeDescription: null,
      totalSections: 3,
      currentIndex: 0,
      reviews: [
        {
          stars: 5,
          review:
            "جزاك الله خير انا حضرت المحاضرة كامله معاها ماشاء الله أسلوبه متميز يتسم بالهدوء أثناء الشرح",
          by: "عبد الله عوده",
        },
        {
          stars: 5,
          review:
            "الله يعطيكم العافية بصراحه تمنيت اني دخلت عبد الرحمن من زمان لاني لاحظت تطور كبير و هذا بفضل الله وتوفيقة ثم بفضل جهدكم معانا واهتمامكم بأدق ادق التفاصيل و متابعة الواجابات و حرصكم بتذكيرنا بمواعيد الاندية كانه ابن لكم",
          by: "ام عبد الرحمن",
        },
        {
          stars: 5,
          review:
            "لا بجد ما شاء الله ربنا يبارك فيها من أجمل واحسن المدرسين اللي في الأكاديمية و غير الضمير الي عندها ربنا يرضيها يارب في حياتها",
          by: "والدة إياد",
        },
        {
          stars: 5,
          review:
            "السلام عليكم ورحمة الله وبركاته نادي القراءة حقيقي اللي مسؤوله عنه مره ممتازة ما شاء الله و بترد في اسرع وقت وتقوم بتحديد الموعد و ملتزمة جدا جزاها الله خيرا",
          by: "فهد محمد",
        },
      ],
      swiper: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperContainer = this.$refs.swiperContainer;
      if (swiperContainer) {
        this.swiper = new Swiper(swiperContainer, {
          loop: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        this.startAutoNavigate();
      }
    });

    this.autoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    startAutoNavigate() {
      setInterval(() => {
        if (this.swiper) {
          this.swiper.slideNext();
        }
      }, 4000);
    },
    toggleDescription(id) {
      this.activeDescription = this.activeDescription === id ? null : id;
    },
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = "";
    },
    generateItems() {
      const items = [];
      const isMobile = window.innerWidth <= 768;

      if (isMobile) {
        items.push({
          src: this.items[this.active].src,
          videoSrc: this.items[this.active].videoSrc,
          level: 0,
        });
      } else {
        for (let i = this.active - 2; i <= this.active + 2; i++) {
          let index = i;
          if (i < 0) index = this.items.length + i;
          if (i >= this.items.length) index = i % this.items.length;

          items.push({
            src: this.items[index].src,
            videoSrc: this.items[index].videoSrc,
            level: this.active - i,
          });
        }
      }

      return items;
    },
    moveLeft() {
      this.active = (this.active - 1 + this.items.length) % this.items.length;
    },
    moveRight() {
      this.active = (this.active + 1) % this.items.length;
    },
    autoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.moveRight();
      }, 4000);
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    },
  },
};
</script>

<style scoped>
.teacher_page {
  background-image: url("https://monglish.b-cdn.net/home/faq_bg.jpg");
  background-size: cover;
  background-position: center;
  height: auto;
  margin-top: -85px;
  padding-top: 105px;
  background-color: white;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  direction: rtl;
}

.teacher_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  flex-wrap: wrap;
}

.teacher_info {
  color: white;
  flex: 1 1 70%;
}

.teacher_image {
  flex: 1 1 30%;
  margin-bottom: 20px;
}

.teacher_image img {
  width: 90%;
}

.teacher_greeting {
  font-size: 2.5rem;
  line-height: 80%;
}

.teacher_name {
  font-size: 3rem;
  margin: 20px 0 25px 0;
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
  line-height: 80%;
}

.teacher_description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.8;
}

.teacher_stats {
  display: flex;
  gap: 40px;
  margin: 20px 0;
}

.stat_number {
  font-size: 2rem;
  display: block;
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}

.stat_label {
  font-size: 1rem;
  color: #fff;
}

.teacher_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.teacher_tags span {
  background: transparent;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  border: 1px solid #fff;
}

.subscribe_button {
  padding: 12px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 25px;
  width: 30%;
  cursor: pointer;
  font-size: 20px;
}

@media (max-width: 1024px) {
  .teacher_container {
    flex-direction: column;
  }

  .teacher_info,
  .teacher_image {
    max-width: 100%;
    flex: 1 1 100%;
  }

  .subscribe_button {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .teacher_greeting {
    font-size: 1.8rem;
    text-align: center;
  }

  .teacher_name {
    font-size: 2.2rem;
    text-align: center;
  }

  .teacher_description {
    text-align: center;
    font-size: 1rem;
  }

  .stat_number {
    font-size: 1.8rem;
  }

  .stat_label {
    font-size: 0.9rem;
  }

  .subscribe_button {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .teacher_greeting {
    font-size: 1.5rem;
  }

  .teacher_name {
    font-size: 2rem;
  }

  .teacher_description {
    font-size: 0.9rem;
  }

  .stat_number {
    font-size: 1.5rem;
  }

  .stat_label {
    font-size: 0.8rem;
  }

  .subscribe_button {
    width: 80%;
  }
}

.promise-sec3-space {
  padding: 2% 5% 2% 5%;
  background-color: #fff;
}

.promise-sec3 {
  position: relative;
  width: 95%;
  height: 65vh;
  background-image: url("https://monglish.b-cdn.net/home/teacherpp.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 25px;
  overflow: hidden;
  flex-direction: row;
  text-align: center;
  background-color: #fff;
}

.promise-sec3-inner {
  width: 100%;
  box-sizing: border-box;
  padding-right: 5%;
}

.text-content-promise {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: right;
}

.text-content-promise h3 {
  font-size: 2em;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}

.text-content-promise h4 {
  font-size: 3em;
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}

.text-content-promise p {
  font-size: 1.1em;
  max-width: 45%;
  margin-top: 15px;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
}

@media (max-width: 768px) {
  .promise-sec3 {
    flex-direction: column;
    height: auto;
  }

  .text-content-promise p {
    max-width: 100%;
  }
}

.slider-3d {
  background-image: url("https://monglish.b-cdn.net/home/bg.jpg");
  background-size: cover;
  background-position: center;
  padding: 3% 1%;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;
}

.carousel-track {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-enter-active,
.slide-leave-active {
  animation: slideIn 0.8s ease forwards;
}

.slide-leave-to {
  animation: slideOut 0.8s ease forwards;
}

h4 {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  font-family: "DIN Next LT Arabic";
  text-align: center;
  padding: 0 0 2% 0;
}

.slider-nav {
  color: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease, transform 0.3s ease;
  border: none;
}

.slider-nav:hover {
  color: #fff;
  transform: scale(1.1);
}

.slider-nav:active {
  color: #165e84;
  transform: scale(1);
}

.slider-nav.left {
  left: 5%;
}

.slider-nav.right {
  right: 5%;
}

.item {
  position: relative;
  text-align: center;
  transition: transform 0.8s ease, opacity 0.8s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.level-2 {
  transform: translateX(91px) rotateY(-10deg) scale(0.6);
  opacity: 0.5;
}

.level-1 {
  transform: translateX(-1px) rotateY(-5deg) scale(0.8);
  opacity: 0.7;
}

.level0 {
  transform: translateX(0) rotateY(0deg) scale(1);
  opacity: 1;
}

.level1 {
  transform: translateX(1px) rotateY(5deg) scale(0.8);
  opacity: 0.7;
}

.level2 {
  transform: translateX(-91px) rotateY(10deg) scale(0.6);
  opacity: 0.5;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 26vw;
  height: 95vh;
  overflow: hidden;
  position: relative;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes popupIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #fff;
}

.close-button:hover {
  color: #d9534f;
}

@media (max-width: 768px) {
  h4 {
    font-size: 28px;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }

  .popup-content {
    width: 95%;
    height: auto;
  }

  .popup-video {
    height: calc(100vw * (16 / 9));
    width: 100%;
  }

  .carousel-track {
    justify-content: center;
  }

  .item {
    transform: none !important;
    opacity: 1 !important;
  }

  img {
    width: 80%;
    height: auto;
  }
}

.kids-sec8 {
  padding: 2%;
  background-color: white;
  height: auto;
}

.kids-sec8-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
  color: #165e84;
}

.kids-sec8-row h4 {
  font-size: 3vw;
}

.kids-sec8-row span {
  font-size: 3.2vw;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}

.kids-sec8-row p {
  font-size: 1.3rem;
  color: gray;
  max-width: 75%;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .kids-sec8-row {
    flex-direction: row;
    justify-content: center;
    text-align: right;
  }

  .kids-sec8-row h4 {
    width: 25%;
    margin: 0;
    padding-right: 1em;
    font-size: 28px;
  }

  .kids-sec8-row p {
    width: 70%;
    margin: 0;
    padding-left: 20px;
  }
}

@media (max-width: 769px) {
  .kids-sec8-row {
    flex-direction: column;
    text-align: center;
  }

  .kids-sec8-row h4 {
    width: 100%;
    padding-right: 0;
    font-size: 28px;
  }

  .kids-sec8-row h4 span {
    width: 100%;
    padding-right: 0;
    font-size: 28px;
    font-weight: bold;
  }

  .kids-sec8-row p {
    width: 100%;
    padding-left: 0;
    font-size: 20px;
  }
}

.adult-swiper {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 2em;
  background-color: #fff;
  margin-top: 1em;
}

.swiper-container {
  width: 100%;
  background-image: url("https://monglish.b-cdn.net/home/accre_bg.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  overflow: hidden;
}

.swiper-slide {
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
}

.swiper-slide p {
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  padding: 1em;
}

.review-stars {
  color: #ff9800;
  font-size: 2.5rem;
}

.review-by {
  margin-top: 15px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;
  text-decoration: underline;
  text-decoration-color: #ff9800;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
  font-family: "DIN Next LT Arabic", sans-serif;
}

.promise-sec3 {
  position: relative;
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 25px;
  overflow: hidden;
  flex-direction: row;
}

.promise-sec3-inner {
  width: 100%;
  box-sizing: border-box;
  padding-right: 5%;
}

.text-content-promise {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: right;
}

.text-content-promise h4 {
  font-size: 3em;
  text-align: right;
}

.text-content-promise p {
  font-size: 1.3em;
  max-width: 45%;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .promise-sec3 {
    flex-direction: column;
    height: auto;
  }

  .text-content-promise h4 {
    font-size: 28px;
  }

  .text-content-promise p {
    max-width: 100%;
    font-size: 20px;
  }

  .swiper-slide {
    font-size: 1em;
    min-height: 100px;
  }

  .swiper-slide p {
    padding: 1em;
  }

  .review-stars {
    font-size: 2rem;
  }

  .review-by {
    margin-top: 5px;
    font-weight: bold;
    font-size: 1rem;
  }
}


.image-container {
  position: relative;
  display: inline-block;
}

.image-thumbnail {
  width: 400px;
  object-fit: cover;
  cursor: pointer;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: transform 0.3s ease;
  pointer-events: none;
}

.image-container:hover .video-icon {
  transform: translate(-50%, -50%) scale(1.5);
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: transform 0.3s ease;
  pointer-events: none;
}

.image-container:hover .video-icon {
  transform: translate(-50%, -50%) scale(1.5);
}
</style>